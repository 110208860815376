import { z } from 'zod';

import { ClientProperty, ClientPropertyKey, ResellerClient } from './types';

import { baseAPI } from '../base-api';
import { Employee } from '../employees/types';
import { MutationRequestOptions, mutationOptionsSchema } from '../helpers';
import { Address } from '../types.shared';

import { RootState, useTypedSelector } from 'store/store';

const endpoints = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    clients: builder.query<ResellerClient[], void>({
      query: () => ({
        url: `clients`,
      }),
      providesTags: (response) => (response !== undefined ? ['Clients'] : []),
    }),
    client: builder.query<ResellerClient, number>({
      query: (id) => ({
        url: `clients/${id}`,
      }),
      providesTags: (response, error, id) =>
        response !== undefined ? [{ type: 'Clients', id }] : [],
    }),
    clientAdmins: builder.query<Employee[], { clientId: number }>({
      query: ({ clientId }) => `fsausers/client-admins/${clientId}`,
      providesTags: (response) => (response !== undefined ? ['Employees'] : []),
    }),
    createClient: builder.mutation<
      ResellerClient,
      Omit<
        ResellerClient,
        'id' | 'address' | 'lastWorkRequestNo' | 'lastCustomerNo'
      > & {
        password: string;
        resellerId: number;
        address: Omit<
          Address,
          | 'id'
          | 'resellerId'
          | 'dateCreated'
          | 'dateModified'
          | 'isDeleted'
          | 'dateDeleted'
          | 'authUsername'
        >;
      }
    >({
      query: (payload) => ({
        url: `clients`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (response) => (response ? ['Clients'] : []),
    }),
    editClient: builder.mutation<
      ResellerClient,
      Omit<
        ResellerClient,
        'address' | 'lastWorkRequestNo' | 'lastCustomerNo'
      > & {
        resellerId: number;
        address: Omit<
          Address,
          | 'id'
          | 'resellerId'
          | 'dateCreated'
          | 'dateModified'
          | 'isDeleted'
          | 'dateDeleted'
          | 'authUsername'
        >;
      }
    >({
      query: (payload) => ({
        url: `clients/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (response, error, payload) =>
        response ? ['Skills', { type: 'Clients', id: payload.id }] : [],
    }),
    saveClientProperty: builder.mutation<
      void,
      {
        propertyName: ClientPropertyKey;
        propertyValue: string;
        clientId: number;
        shouldUpdate?: boolean;
      }
    >({
      query: ({ shouldUpdate = false, ...payload }) => ({
        url: 'property-client',
        method: shouldUpdate ? 'PUT' : 'POST',
        body: { ...payload },
      }),
      invalidatesTags: (res, err, arg) =>
        res ? [{ type: 'Clients', id: `property-${arg.propertyName}` }] : [],
    }),
    saveClientProperties: builder.mutation<
      ClientProperty[],
      {
        propertyName: ClientPropertyKey;
        propertyValue: string;
        clientId: number;
      }[]
    >({
      query: (payload) => ({
        url: 'property-client/bulk',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (res, err, arg) =>
        res ? [{ type: 'Clients', id: 'properties' }] : [],
    }),
    clientProperty: builder.query<
      string,
      { name: ClientPropertyKey; clientId: number }
    >({
      query: (args) =>
        `property-client?property-name=${args.name}&client-id=${args.clientId}`,
      transformResponse(baseQueryReturnValue: any, meta, arg) {
        return baseQueryReturnValue.propertyValue;
      },
      providesTags: (res, err, arg) =>
        res ? [{ type: 'Clients', id: `property-${arg.name}` }] : [],
    }),
    clientProperties: builder.query<ClientProperty[], { clientId: number }>({
      query: (args) => `property-client/client/${args.clientId}`,
      providesTags: (res, err, arg) =>
        res ? [{ type: 'Clients', id: 'properties' }] : [],
    }),
    syncCustomers: builder.mutation<
      string,
      'sync-to-quickbooks' | 'sync-from-quickbooks'
    >({
      query: (arg) => {
        const url: Record<
          'sync-to-quickbooks' | 'sync-from-quickbooks',
          string
        > = {
          'sync-from-quickbooks': 'quickbooks/customer-import',
          'sync-to-quickbooks': 'quickbooks/customer-export',
        };
        return {
          url: url[arg],
          method: 'POST',
        };
      },
    }),
    isQuickbooksIntegrated: builder.query<
      { isLoggedIn: boolean; expireTime?: number; message: string },
      void
    >({
      async queryFn(arg, api, extraOptions, baseQuery) {
        const response = await baseQuery({
          url: `quickbooks/is-logged-in`,
          params: {
            auth: (api.getState() as RootState).auth.accessToken,
          },
        });
        const schema = z.object({
          isLoggedIn: z.boolean(),
          expireTime: z.number().optional(),
          message: z.string(),
        });
        const parsed = schema.safeParse(response.data);
        if (response.error) {
          return { error: response.error };
        }
        if (!parsed.success) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: 'Failed to parse response.',
            },
          };
        }
        return {
          data: parsed.data,
        };
      },
      providesTags: [{ type: 'Clients', id: 'quickbooks-integration' }],
    }),
    _isQuickbooksIntegrated: builder.mutation<
      { isLoggedIn: boolean; expireTime?: number; message: string },
      void
    >({
      async queryFn(arg, api, extraOptions, baseQuery) {
        const response = await baseQuery({
          url: `quickbooks/is-logged-in`,
          params: {
            auth: (api.getState() as RootState).auth.accessToken,
          },
        });
        const schema = z.object({
          isLoggedIn: z.boolean(),
          expireTime: z.number().optional(),
          message: z.string(),
        });
        const parsed = schema.safeParse(response.data);
        if (response.error) {
          return { error: response.error };
        }
        if (!parsed.success) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: 'Failed to parse response.',
            },
          };
        }
        return {
          data: parsed.data,
        };
      },
      invalidatesTags: [{ type: 'Clients', id: 'quickbooks-integration' }],
    }),
    disconnectQuickbooks: builder.mutation<any, void>({
      query() {
        return {
          url: 'quickbooks/logout',
          method: 'POST',
        };
      },
      invalidatesTags: [{ type: 'Clients', id: 'quickbooks-integration' }],
    }),
    license: builder.query<
      {
        clientId: number;
        maxNumTechnicians: number;
        currentNumTechnicians: number;
      },
      { clientId: number }
    >({
      query: (arg) => ({ url: `clients/${arg.clientId}/license` }),
      providesTags: (res, err, arg) =>
        res ? [{ type: 'Clients', id: `${arg.clientId}:license` }] : [],
    }),
    updateLicense: builder.mutation<
      {
        currentNumTechnicians: number;
      },
      { maxNumTechnicians: number; clientId: number } & MutationRequestOptions
    >({
      query: (arg) => ({
        url: `clients/${arg.clientId}/license`,
        method: 'POST',
        body: { maxNumTechnicians: arg.maxNumTechnicians },
      }),
      invalidatesTags: (res, err, arg) => {
        const invalidate = mutationOptionsSchema.parse(
          arg.mutationOptions,
        ).invalidate;
        return !err && invalidate
          ? [{ type: 'Clients', id: `${arg.clientId}:license` }]
          : [];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useClientsQuery,
  useClientQuery,
  useCreateClientMutation,
  useEditClientMutation,
  useClientAdminsQuery,
  useSaveClientPropertyMutation,
  useClientPropertyQuery,
  useSyncCustomersMutation,
  useIsQuickbooksIntegratedQuery,
  useDisconnectQuickbooksMutation,
  useSaveClientPropertiesMutation,
} = endpoints;

export { endpoints as ClientService };
export const useClientPropertiesQuery = () => {
  const clientId = useTypedSelector((s) => s.auth.userCredentials.clientId);
  return endpoints.useClientPropertiesQuery(
    { clientId },
    { skip: clientId === 0 },
  );
};
