import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'baseui/snackbar';
import clsx from 'clsx';
import parseDuration from 'parse-duration';
import prettyMilliseconds from 'pretty-ms';
import { millisecondsToMinutes } from 'date-fns';
import {
  DollarSign as DollarSignIcon,
  Edit as EditIcon,
  Plus as PlusIcon,
} from 'react-feather';
import { useForm } from 'react-hook-form';
import { Outlet } from 'react-router-dom';
import { z } from 'zod';

import { MutationBanner } from 'components/Banner';
import { FieldGroup } from 'components/forms/fieldset';
import { createTableBuilder } from 'components/Table';
import { Button } from 'components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'components/ui/card';
import { Input, TextField } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { DashboardContentHeader } from 'features/DashboardContentHeader';
import { isMutationSuccess } from 'services/api/base-api';
import {
  useSaveWorkTypeMutation,
  useWorkTypesQuery,
} from 'services/api/work-type/endpoints';
import { WorkType } from 'services/api/work-type/types';
import { priceFormatter } from 'utils/helpers';
import { useLocale } from 'utils/hooks/useLocale';

export default function RecurringJobsIndex() {
  const { t } = useLocale();

  return (
    <>
      <Outlet />
      <div className="flex-1 space-y-5">
        <DashboardContentHeader
          title={t('Recurring Jobs')}
          description={t('Manage your Jobs')}
        />
        <section className="flex items-start space-x-4">
          <div className="w-full">
            <JobsTable />
          </div>
          <aside className={clsx('w-96 shrink-0')}>
            <NewJobForm />
          </aside>
        </section>
      </div>
    </>
  );
}

const nonEmptyString = z
  .string()
  .trim()
  .min(1, { message: 'errors.invalid_type_received_undefined' });

const schema = z.object({
  name: nonEmptyString,
  duration: z
    .string()
    .refine((value) => parseDuration(value) !== null, {
      params: { i18n: 'invalid_duration' },
    })
    .refine((value) => millisecondsToMinutes(parseDuration(value)) >= 15, {
      params: { i18n: 'invalid_duration' },
    }),
  price: z.coerce
    .number({
      invalid_type_error: 'price_required',
      required_error: 'price_required',
    })
    .min(1, { message: 'errors.invalid_type_received_undefined' }),
});
type FormFields = z.infer<typeof schema>;

function NewJobForm() {
  const { t } = useLocale();
  const snackbar = useSnackbar();
  const { control, handleSubmit } = useForm<FormFields>({
    defaultValues: {
      duration: '',
      name: '',
    },
    resolver: zodResolver(schema),
  });
  const [createWorkType, mutation] = useSaveWorkTypeMutation();
  const onSubmit = handleSubmit(async (data) => {
    const result = await createWorkType({
      type: 'create',
      payload: {
        ...data,
        estimatedDuration: parseDuration(data.duration, 's'),
      },
    });
    if (isMutationSuccess(result)) {
      snackbar.enqueue({ message: t('recurring_job_created_successfully') });
    }
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('New Job')}</CardTitle>
        <CardDescription>{t('recurring_job_form_description')}</CardDescription>
      </CardHeader>
      <CardContent>
        <MutationBanner
          show={mutation.isError}
          message={t('recurring_job_created_error')}
        />
        <form onSubmit={onSubmit} id="create-recurring-job-form">
          <FieldGroup>
            <TextField control={control} field="name">
              <Label>{t('Job Name')}</Label>
              <Input placeholder="Cable Repair" />
            </TextField>
            <div className="grid gap-x-4 gap-y-5 lg:grid-cols-2">
              <TextField control={control} field="duration">
                <Label>{t('Estimated Time')}</Label>
                <Input placeholder="2h" />
              </TextField>
              <TextField control={control} field="price">
                <Label>{t('Cost')}</Label>
                <Input startEnhancer={<DollarSignIcon data-slot="icon" />} />
              </TextField>
            </div>
          </FieldGroup>
        </form>
      </CardContent>
      <CardFooter>
        <Button
          size="lg"
          type="submit"
          form="create-recurring-job-form"
          className="w-full"
          startEnhancer={<PlusIcon />}
          isLoading={mutation.isLoading}
        >
          {t('Add New Job')}
        </Button>
      </CardFooter>
    </Card>
  );
}

const { Table, Column } = createTableBuilder<WorkType>();
function JobsTable() {
  const { t } = useLocale();
  const query = useWorkTypesQuery();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('Recurring Jobs')}</CardTitle>
      </CardHeader>
      <CardContent>
        <Table
          data={query.data ?? []}
          isLoading={query.isLoading}
          isError={query.isError}
          isEmpty={query.isSuccess && query.data.length === 0}
        >
          <Column header={t('ID#')}>{(row) => row.id}</Column>
          <Column header={t('Job Name')}>{(row) => row.name}</Column>
          <Column header={t('Estimated Time')}>
            {(row) =>
              prettyMilliseconds(parseDuration(`${row.estimatedDuration}`), {
                verbose: true,
                keepDecimalsOnWholeSeconds: true,
              })
            }
          </Column>
          <Column header={t('Cost')}>
            {(row) => `${priceFormatter().format(row.price)}`}
          </Column>
          <Column header={t('Action')}>
            {(row) => (
              <Button href={`${row.id}/edit`} variant="plain">
                <EditIcon data-slot="icon" />
              </Button>
            )}
          </Column>
        </Table>
      </CardContent>
    </Card>
  );
}
