import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'baseui/snackbar';
import parseDuration from 'parse-duration';
import prettyMilliseconds from 'pretty-ms';
import { millisecondsToMinutes } from 'date-fns';
import { DollarSign as DollarSignIcon } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

import { MutationBanner } from 'components/Banner';
import { FieldGroup } from 'components/forms/fieldset';
import { QueryResolver } from 'components/QueryResolver';
import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from 'components/ui/dialog';
import { Input, TextField } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { isMutationSuccess } from 'services/api/base-api';
import {
  useSaveWorkTypeMutation,
  useWorkTypeQuery,
} from 'services/api/work-type/endpoints';
import { useLocale } from 'utils/hooks/useLocale';

export default function EditRecurringJob() {
  const { t } = useLocale();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const params = useParams();
  const jobsQuery = useWorkTypeQuery(Number(params.jobId ?? '0'));
  const [editWorkType, mutation] = useSaveWorkTypeMutation();

  return (
    <Dialog onOpenChange={(open) => !open && navigate('..')} defaultOpen>
      <DialogTitle>{t('Edit Job')}</DialogTitle>
      <DialogBody>
        <MutationBanner show={mutation.isError} message={t('mutation_error')} />
        <QueryResolver query={jobsQuery}>
          {(job) => (
            <RecurringJobForm
              id="edit-recurring-job-form"
              initialValue={{
                name: job.name,
                duration: prettyMilliseconds(
                  parseDuration(`${job.estimatedDuration}`),
                ),
                price: job.price,
              }}
              onSubmit={async (data) => {
                const result = await editWorkType({
                  type: 'update',
                  payload: {
                    ...job,
                    name: data.name,
                    estimatedDuration: parseDuration(data.duration, 's'),
                    price: data.price,
                  },
                });

                if (isMutationSuccess(result)) {
                  snackbar.enqueue({
                    message: t('recurring_job_updated_successfully'),
                  });
                  navigate('..');
                }
              }}
            />
          )}
        </QueryResolver>
      </DialogBody>
      <DialogActions>
        <Button slot="close" size="lg" variant="plain">
          {t('Cancel')}
        </Button>
        <Button
          size="lg"
          type="submit"
          form="edit-recurring-job-form"
          isLoading={mutation.isLoading}
        >
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
const nonEmptyString = z
  .string()
  .trim()
  .min(1, { message: 'errors.invalid_type_received_undefined' });
const schema = z.object({
  name: nonEmptyString,
  duration: z
    .string()
    .refine((value) => parseDuration(value) !== null, {
      params: { i18n: 'invalid_duration' },
    })
    .refine((value) => millisecondsToMinutes(parseDuration(value)) >= 15, {
      params: { i18n: 'invalid_duration' },
    }),
  price: z.coerce
    .number({
      invalid_type_error: 'errors.invalid_type_received_undefined',
      required_error: 'errors.invalid_type_received_undefined',
    })
    .min(1, { message: 'errors.invalid_type_received_undefined' }),
});
type FormFields = z.infer<typeof schema>;

function RecurringJobForm({
  initialValue,
  onSubmit: onSubmitHandler,
  id = '',
}: {
  initialValue?: FormFields;
  onSubmit?: (data: FormFields) => void;
  id?: string;
}) {
  const { t } = useLocale();
  const { control, handleSubmit } = useForm<FormFields>({
    defaultValues: initialValue ?? {},
    resolver: zodResolver(schema),
  });
  const onSubmit = handleSubmit((data) => {
    if (onSubmitHandler) {
      onSubmitHandler(data);
    }
  });

  return (
    <form onSubmit={onSubmit} id={id}>
      <FieldGroup>
        <TextField control={control} field="name">
          <Label>{t('Job Name')}</Label>
          <Input placeholder="Cable Repair" />
        </TextField>
        <div className="grid gap-x-4 gap-y-5 lg:grid-cols-2">
          <TextField control={control} field="duration">
            <Label>{t('Estimated Time')}</Label>
            <Input placeholder="2h" />
          </TextField>
          <TextField control={control} field="price">
            <Label>{t('Cost')}</Label>
            <Input startEnhancer={<DollarSignIcon data-slot="icon" />} />
          </TextField>
        </div>
      </FieldGroup>
    </form>
  );
}
