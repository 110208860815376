import { z } from 'zod';

import { USER_ROLE_VALUES, WORK_REQUEST_STATUS_VALUES } from './constants';
import { DayConstraint } from './work-requests/types';
type DeepNullable<T> = {
  [K in keyof T]: DeepNullable<T[K]> | null;
};
type Nullable<T> = { [K in keyof T]: T[K] | null };

type PickNullable<T, K extends keyof T> = {
  [P in K]: Nullable<T[P]>;
};

export type WorkRequestStatus = keyof typeof WORK_REQUEST_STATUS_VALUES;
export type UserRole = keyof typeof USER_ROLE_VALUES;

export type PaginatedResponse<T> = {
  list: T[];
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
};

export type WithPaginationResponse<T> = T & {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
};

export const paginatedParamsSchema = z.object({
  page: z.coerce.number().optional(),
  limit: z.coerce.number().optional(),
});
export type PaginatedParams = z.infer<typeof paginatedParamsSchema>;

const nonEmptyString = z.string().trim().min(1, { message: 'Required' });
export const rawAddressSchema = z.object({
  id: z.number(),
  street: nonEmptyString,
  street2: z.string().trim().nullable(),
  city: nonEmptyString,
  state: nonEmptyString,
  postalCode: nonEmptyString,
  country: nonEmptyString,
  lat: z.number(),
  lng: z.number(),
  timeZone: z.string().trim().nullable(),
  resellerId: z.number(),
  dateCreated: z.string(),
  dateModified: z.string(),
  isDeleted: z.boolean(),
  dateDeleted: z.string().nullable()
});

type RawAddress = z.infer<typeof rawAddressSchema>;

export type ModifiableAddressPayload = Omit<
  RawAddress,
  'resellerId' | 'dateCreated' | 'dateModified' | 'isDeleted' | 'dateDeleted'
>;

export type Address = Omit<
  RawAddress,
  'resellerId' | 'dateCreated' | 'dateModified' | 'isDeleted' | 'dateDeleted'
> &
  Partial<
    Pick<
      RawAddress,
      | 'resellerId'
      | 'dateCreated'
      | 'dateModified'
      | 'isDeleted'
      | 'dateDeleted'
    >
  >;

export type PortalWorkRequest = {
  id: number;
  estimateId: number | null;
  invoiceId: number | null;
  num: number;
  customer: number;
  workType: string;
  estimateRequired: boolean;
  recurring: boolean;
  progress: WorkRequestStatus;
  custName: string;
  phone: string;
  email: string;
  address: Address;
  notes?: string;
  workTypeDescription: string;
  dateConstraints: DayConstraint[];
  timeConstraintType: string | null;
  timeConstraint: string | null;
};

export type PortalEstimate = {
  estimateScheduleDate: string | null;
  estimateTimeStart: string | null;
  estimateTimeEnd: string | null;
  estimateActualDateTimeStart: string | null;
  estimateActualDateTimeEnd: string | null;
  estimateScheduledBy: string | null;
  estimateAssignedTo: AssignedTo[];
  estimateSent: string | null;
  estimateApproved: string | null;
  estimateActualDate: string | null;
  estimateActualTimeStart: string | null;
  estimateActualTimeEnd: string | null;
  estimateCreatedBy: string | null;
};

export type PortalInvoice = {
  invoiceSent: string | null;
  invoicePaid: string | null;
  invoiceCreatedBy: string | null;
  invoiceDue: string | null;
  invoiceTotal: number;
};

export type PortalJob = {
  jobScheduleDate: string | null;
  jobTimeStart: string | null;
  jobTimeEnd: string | null;
  jobActualDateTimeStart: string | null;
  jobActualDateTimeEnd: string | null;
  jobScheduledBy: string | null;
  jobAssignedTo: AssignedTo[];
  jobActualDate: string | null;
  jobActualTimeStart: string | null;
  jobActualTimeEnd: string | null;
};

export type AssignedTo = {
  name: string;
  occupation: string;
};

/**
 * TODO: Rename to invoiceSchema once figured out how to properly structure data
 */
export const invoiceSpecificsSchema = z.object({
  id: z.number(),
  workRequestId: z.number(),
  notes: z.string(),
  dateSent: z.string().nullable(),
  dateDue: z.string().nullable(),
  datePaid: z.string().nullable(),
  subTotal: z.number(),
  taxable: z.number(),
  taxPercentage: z.number(),
  tax: z.number(),
  deposit: z.number(),
  total: z.number(),
  createdById: z.number(),
  dateCreated: z.string(),
  dateModified: z.string(),
  isDeleted: z.boolean(),
  dateDeleted: z.string().nullable(),
});
export type InvoiceAmounts = z.infer<typeof invoiceSpecificsSchema>;
export type WorkRequestAmounts = {
  id: number;
  workRequestId: number;
  notes: string | null;
  dateSent: string | null;
  dateDue: string | null;
  dateApproved: string | null;
  total: number;
  subTotal: number;
  taxable: number;
  taxPercentage: number;
  tax: number;
  hours: string | null;
  itemCost: number | null;
  deposit: number;
  createdById: number;
};
