import {
  getLocalTimeZone,
  parseDate,
  parseDateTime,
} from '@internationalized/date';
import { isDate, isToday } from 'date-fns';
import { useContext } from 'react';
import {
  Button as AriaButton,
  Calendar as AriaCalendar,
  CalendarProps as AriaCalendarProps,
  DateInput as AriaDateInput,
  DateInputProps as AriaDateInputProps,
  DatePicker as AriaDatePicker,
  DatePickerProps as AriaDatePickerProps,
  DatePickerStateContext as AriaDatePickerStateContext,
  DateSegment as AriaDateSegment,
  Dialog as AriaDialog,
  Group as AriaGroup,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  DateValue,
  I18nProvider,
} from 'react-aria-components';
import {
  Calendar as CalendarIcon,
  ChevronLeft,
  ChevronRight as ChevronRightIcon,
  X as XIcon,
} from 'react-feather';
import { useController } from 'react-hook-form';
import { tv } from 'tailwind-variants';

import { withControlledField } from './utils';

import {
  ControlledFieldProps,
  FieldErrorMessage,
  fieldStyles,
} from 'components/forms/fieldset';
import { Button } from 'components/ui/button';
import { Heading } from 'components/ui/heading';
import { Popover } from 'components/ui/popover';
import { cn, toPayloadDateFormat, toPayloadTimeFormat } from 'utils/helpers';
import { useLocale } from 'utils/hooks/useLocale';

function _DatePickerField({
  children,
  control,
  field: fieldName,
  ...props
}: AriaDatePickerProps<DateValue> & ControlledFieldProps) {
  const controller = useController({ control, name: fieldName });
  const field = controller.field;
  const error = !!controller.fieldState.error?.message;

  return (
    <AriaDatePicker
      {...props}
      ref={field.ref}
      name={field.name}
      value={
        field.value && isDate(field.value)
          ? parseDate(toPayloadDateFormat(new Date(field.value)))
          : null
      }
      className={fieldStyles({ className: 'group' })}
      onChange={(selected) => {
        const value: typeof selected | null = selected;
        field.onChange(value ? value.toDate(getLocalTimeZone()) : '');
      }}
      onBlur={field.onBlur}
      isInvalid={error}
    >
      {(renderProps) => (
        <>
          {typeof children === 'function' ? children(renderProps) : children}
          <FieldErrorMessage control={control} field={fieldName} />
        </>
      )}
    </AriaDatePicker>
  );
}
export const DatePickerField = withControlledField(_DatePickerField);

const segmentStyles = tv({
  base: 'type-literal:px-0 forced-color-adjust-none forced-colors:text-[ButtonText] inline rounded p-0.5 text-gray-800 caret-transparent outline outline-0 dark:text-zinc-200',
  variants: {
    isPlaceholder: {
      true: 'italic text-gray-600 dark:text-zinc-400',
    },
    isDisabled: {
      true: 'forced-colors:text-[GrayText] text-gray-200 dark:text-zinc-600',
    },
    isFocused: {
      true: 'forced-colors:bg-[Highlight] forced-colors:text-[HighlightText] bg-blue-600 text-white dark:text-white',
    },
  },
});

export function DatePicker({
  variant = 'default',
  clearable = false,
  min,
}: {
  variant?: 'material' | 'default';
  clearable?: boolean;
  min?: Date;
}) {
  return (
    <>
      <AriaGroup className="group relative z-0" data-slot="control">
        <DateInput variant={variant}>
          {(segment) => (
            <AriaDateSegment segment={segment} className={segmentStyles} />
          )}
        </DateInput>
        <AriaButton
          className={cn([
            'absolute inset-0 flex w-full items-center justify-end rounded pr-3 text-zinc-950/60 outline-none outline-offset-0',
          ])}
        >
          <CalendarIcon aria-hidden className="h-4 w-4" />
        </AriaButton>
        {clearable && (
          <span className="absolute inset-y-0 right-0 flex -translate-x-9 items-center justify-center">
            <ClearButton />
          </span>
        )}
      </AriaGroup>
      <Popover>
        <AriaDialog className="relative max-h-[inherit] overflow-auto p-6 outline outline-0 [[data-placement]>&]:p-4">
          <Calendar
            minValue={
              min
                ? parseDateTime(
                    `${toPayloadDateFormat(min)}T${toPayloadTimeFormat(min, {
                      includeSeconds: true,
                    })}`,
                  )
                : undefined
            }
          />
        </AriaDialog>
      </Popover>
    </>
  );
}

function ClearButton() {
  const state = useContext(AriaDatePickerStateContext);
  const hasValue = !!state.value?.toString();

  if (!hasValue) {
    return null;
  }

  return (
    <button
      onClick={() => state.setValue(null)}
      className="text-zinc-500 hover:text-zinc-950"
      title="Clear Selection"
      type="button"
      data-slot="icon"
    >
      <XIcon aria-hidden strokeWidth={2.5} className="h-4 w-4" />
    </button>
  );
}

function Calendar<T extends DateValue>(props: AriaCalendarProps<T>) {
  const { currentLocale } = useLocale();
  return (
    <I18nProvider locale={currentLocale}>
      <AriaCalendar aria-label="Calendar" {...props}>
        <header className="flex items-center text-gray-900">
          <Button slot="previous" type="button" variant="plain" square>
            <ChevronLeft className="h-5 w-5" aria-hidden="true" />
          </Button>
          <div className="flex-auto text-center">
            <Heading className="" />
          </div>
          <Button slot="next" type="button" variant="plain" square>
            <span className="sr-only">Next month</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </Button>
        </header>
        <CalendarGrid className="mt-5 w-full overflow-hidden rounded bg-white text-sm">
          <CalendarGridHeader>
            {(day) => (
              <CalendarHeaderCell>
                <div className="flex h-10 w-10 items-center justify-center text-gray-500">
                  <span>{day}</span>
                </div>
              </CalendarHeaderCell>
            )}
          </CalendarGridHeader>
          <CalendarGridBody>
            {(date) => (
              <CalendarCell date={date} className="group">
                {(rp) => (
                  <span
                    className={cn(
                      'mx-auto w-10 h-10 flex items-center justify-center ',
                      [
                        'group-data-[hovered]:bg-gray-50 group-data-[hovered]:text-gray-900',
                      ],
                      {
                        'bg-brand-primary-600 text-white rounded':
                          // rp.isSelected && isToday(date.toDate(getTimezone())),
                          rp.isSelected,
                        'rounded-l-lg': rp.isSelectionStart,
                        'rounded-r-lg': rp.isSelectionEnd,
                        rounded: !rp.isSelected,
                        'bg-gray-50 text-gray-200': rp.isDisabled,
                        'line-through text-red-500': rp.isUnavailable,
                        'font-semibold': isToday(
                          date.toDate(getLocalTimeZone()),
                        ),
                      },
                    )}
                  >
                    {date.day}
                  </span>
                )}
              </CalendarCell>
            )}
          </CalendarGridBody>
        </CalendarGrid>
      </AriaCalendar>
    </I18nProvider>
  );
}

const dateInputVariants = tv({
  base: 'peer form-input block w-full text-base text-gray-900 transition',
  variants: {
    variant: {
      material: 'border-0 bg-white px-3.5 py-2.5',
      default:
        'rounded-lg border-zinc-950/10 bg-white px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)]',
    },
  },
});
function DateInput({
  variant,
  ...props
}: AriaDateInputProps & { variant?: 'material' | 'default' }) {
  return (
    <div className="relative" data-slot="control">
      <AriaDateInput
        {...props}
        className={cn([
          // Base
          dateInputVariants({ variant }),

          // Hover
          'group-data-[hovered]:bg-gray-50 ',

          // Focus
          'outline-none group-data-[focus-within]:bg-gray-50',

          // Invalid state
          'data-[invalid]:border-red-500 data-[invalid]:data-[hovered]:border-red-500',

          // Disabled state
          'data-[disabled]:border-zinc-950/20',
        ])}
        data-slot="control"
      />

      {/* <AriaDateInput
        {...props}
        data-slot="control"
        className={cn([
          // Base
          'peer form-input transition block w-full rounded-lg border-zinc-950/10 bg-white px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)] text-base text-gray-900 hover:bg-gray-50 focus:bg-gray-50 focus:ring-0',

          // Invalid state
          'data-[invalid]:border-red-500 data-[invalid]:data-[hovered]:border-red-500',

          // Disabled state
          'data-[disabled]:border-zinc-950/20',
        ])}
      /> */}
      {variant === 'material' && (
        <div
          className={cn([
            // Base
            'absolute inset-x-0 bottom-0 border-t border-gray-300 peer-data-[focus-within]:border-t-2 peer-data-[focus-within]:border-brand-primary-600',

            // Invalid state
            'peer-data-[invalid]:border-red-500 peer-data-[invalid]:peer-data-[hovered]:border-red-500',

            // Disabled state
            'peer-data-[disabled]:border-zinc-950/20',
          ])}
          aria-hidden="true"
        />
      )}
    </div>
  );
}
