import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import CustomerDetails from './customers/customer-details';
import Customers from './customers/customers-index';
import EditCustomer from './customers/edit-customer';
import NewCustomer from './customers/new-customer';
import NewNote from './customers/new-note';
import EditEmployee from './employees/edit-employee';
import EmployeeDetails from './employees/employee-details/route';
import EmployeesIndex from './employees/employees-index';
import NewEmployee from './employees/new-employee';
import NewSkillset from './employees/new-skillset';
import AddEquipment from './equipments/add-equipment';
import DeleteEquipment from './equipments/delete-equipment';
import EditEquipment from './equipments/edit-equipment';
import EquipmentsIndex from './equipments/equipment-index';
import EditRecurringJob from './jobs/edit-recurring-job';
import RecurringJobsIndex from './jobs/recurring-jobs-index';
import SettingIndexPage from './settings/settings-index';

import Root from 'pages/fs-admin/root';
import { EnvironmentFlagged } from 'utils/flags';

export const BASE_SLUG = 'fs-admin';

export default function FSAdminRoutes() {
  return (
    <Routes>
      <Route element={<Root />}>
        <Route index element={<Navigate to="employees" />} />
        {/* <Route index element={<ActivityIndex />} /> */}
        <Route path="equipments" element={<EquipmentsIndex />}>
          <Route path="new" element={<AddEquipment />} />
          <Route path=":equipmentId" element={<Outlet />}>
            <Route path="delete" element={<DeleteEquipment />} />
            <Route path="update" element={<EditEquipment />} />
          </Route>
        </Route>
        <Route path="jobs" element={<RecurringJobsIndex />}>
          <Route path=":jobId/edit" element={<EditRecurringJob />} />
        </Route>
        <Route path="employees" element={<EmployeesIndex />}>
          <Route path="new-skillset" element={<NewSkillset />} />
          <Route path="new" element={<NewEmployee />} />
        </Route>
        <Route path="employees/:employeeId" element={<EmployeeDetails />}>
          <Route path="edit" element={<EditEmployee />} />
        </Route>
        <Route path="customers" element={<Outlet />}>
          <Route index element={<Customers />} />
          <Route path="new" element={<NewCustomer />} />
          <Route path=":customerId" element={<CustomerDetails />}>
            <Route path="new-note" element={<NewNote />} />
          </Route>
          <Route path=":customerId/edit" element={<EditCustomer />} />
        </Route>
        <Route path="settings" element={<SettingIndexPage />} />
        <Route
          path="*"
          element={
            <div className="mx-auto flex h-full max-w-md items-center justify-center">
              <div className="text-center font-prompt">
                <h1 className="text-5xl font-bold text-brand-primary">404</h1>
                <p className="text-2xl text-brand-primary">Not found</p>
              </div>
            </div>
          }
        />
      </Route>
    </Routes>
  );
}
