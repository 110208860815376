import { WorkType } from './types';

import { baseAPI } from '../base-api';

const workTypeEndpoints = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    workTypes: builder.query<WorkType[], void>({
      query: () => 'worktypes',
      providesTags: ['Work-Type'],
    }),
    workType: builder.query<WorkType, number>({
      query: (id) => `worktypes/${id}`,
      providesTags: (id) => [{ type: 'Work-Type', id: id?.id }],
    }),
    saveWorkType: builder.mutation<
      WorkType,
      | {
          type: 'create';
          payload: Omit<
            WorkType,
            'id' | 'clientId' | 'resellerId' | 'estimatedDuration'
          > & {
            estimatedDuration: number;
          };
        }
      | {
          type: 'update';
          payload: Omit<WorkType, 'estimatedDuration'> & {
            estimatedDuration: number;
          };
        }
    >({
      query: (args) => {
        const { type, payload } = args;
        const method = {
          create: 'POST',
          update: 'PUT',
        }[type];

        return {
          url: type === 'create' ? `worktypes` : `worktypes/${payload.id}`,
          method: method,
          body: { ...payload, description: '' },
        };
      },
      invalidatesTags: (workType) => (workType ? ['Work-Type'] : []),
    }),
  }),
  overrideExisting: false,
});

export const { useWorkTypesQuery, useWorkTypeQuery, useSaveWorkTypeMutation } =
  workTypeEndpoints;
