import { format } from 'date-fns';
import { z } from 'zod';

import { workRequestStatusSchema } from '../work-requests/types';

const SCHEDULE_TYPE_VALUE = {
  EstimateSchedule: 'EstimateSchedule',
  JobSchedule: 'JobSchedule',
} as const;
export const scheduleSlotTypeSchema = z.nativeEnum(SCHEDULE_TYPE_VALUE);
export type ScheduleSlotType = z.infer<typeof scheduleSlotTypeSchema>;
const SCHEDULE_LINEUP_STATUS_VALUE = {
  Ahead: 'Ahead',
  Behind: 'Behind',
} as const;
export const scheduleLineupStatusSchema = z.nativeEnum(
  SCHEDULE_LINEUP_STATUS_VALUE,
);
export type ScheduleLineupStatus = z.infer<typeof scheduleLineupStatusSchema>;

export const schedulesParamsSchema = z.object({
  clientId: z.number(),
  dateFrom: z.string().optional(),
  dateTo: z.string().optional(),
  search: z.string().optional(),
  includeIsCancelled: z.boolean().optional(),
  status: scheduleLineupStatusSchema.optional(),
  techId: z.number().optional(),
  referenceTime: z.string().optional(),
});
export type SchedulesParams = z.infer<typeof schedulesParamsSchema>;

export type ScheduleItem = {
  schedDays: {
    dateFrom: string;
    dateTo: string;
  };
  techs: {
    id: number;
    name: string;
    lastDay: string | null;
  }[];
  schedRows: ScheduleSlot[];
};

export const scheduleSlotSchema = z.object({
  id: z.number(),
  scheduledById: z.number(),
  scheduleDay: z.string(),
  scheduleType: scheduleSlotTypeSchema,
  driveStartTime: z.string().nullable(),
  beginTime: z.string(),
  endTime: z.string(),
  techId: z.number(),
  skillUsed: z.string(),
  isCancelled: z.boolean(),
  num: z.number(),
  workRequestId: z.number(),
  workType: z.string(),
  actualDay: z.string().nullable(),
  actualBeginTime: z.string().nullable(),
  actualEndTime: z.string().nullable(),
  dateCreated: z.string(),
  dateModified: z.string(),
  isDeleted: z.boolean(),
  dateDeleted: z.string().nullable(),
  status: workRequestStatusSchema,
  custLastName: z.string().optional(),
  custFirstName: z.string().optional(),
  servLocDateTime: z.string().optional(),
});

export type ScheduleSlot = z.infer<typeof scheduleSlotSchema>;

export type CreateSheduleSlot = Omit<
  ScheduleSlot,
  | 'id'
  | 'actualDay'
  | 'actualBeginTime'
  | 'actualEndTime'
  | 'dateCreated'
  | 'dateModified'
  | 'isDeleted'
  | 'dateDeleted'
  | 'isCancelled'
  | 'num'
  | 'workType'
  | 'status'
>;

const openingTimeslotSchema = z.object({
  beginTime: z.string(),
  endTime: z.string(),
  isWithinRadius: z.boolean(),
  distance: z.number().nullable(),
});
export type OpeningTimeslot = z.infer<typeof openingTimeslotSchema>;

export const scheduleOpeningsSchema = z.object({
  schedDay: z.string(),
  techs: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      isWithinRadius: z.boolean(),
      distance: z.number().nullable(),
      lastDay: z.string().nullish(),
    }),
  ),
  openRows: z.record(z.string(), z.array(openingTimeslotSchema)),
  workRequestDuration: z.string(),
  prefTechId: z.number().nullable(),
});

export const findScheduleSchema = z.array(
  z.object({
    schedDay: z.string(),
    techId: z.number(),
    beginTime: z.string(),
    endTime: z.string(),
    servLocDateTime: z.string(),
  }),
);

export type FindSchedule = z.infer<typeof findScheduleSchema>[number];

export type ScheduleOpenings = z.infer<typeof scheduleOpeningsSchema>;

export const nextAvailableOpeningSchema = z.object({
  daysToNextOpening: z.number(),
  schedDay: z.preprocess(
    (v) => new Date(`${v}T00:00:00`).toISOString(),
    z.string(),
  ),
  time: z.preprocess(
    (v) => new Date(`${format(new Date(), 'yyyy-MM-dd')}T${v}`).toISOString(),
    z.string(),
  ),
});
export const nextOpeningParamsSchema = z.object({
  refDate: z.string(),
  duration: z.string().optional(),
  refTime: z.string(),
});

export type NextAvailableOpening = z.infer<typeof nextAvailableOpeningSchema>;
export type NextOpeningParams = z.infer<typeof nextOpeningParamsSchema>;

export const RECURRING_SCHEDULE_OPTIONS = {
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
} as const;
export type RecurringScheduleOptions =
  typeof RECURRING_SCHEDULE_OPTIONS[keyof typeof RECURRING_SCHEDULE_OPTIONS];

export type RecurringSchedulePayload = {
  scheduleItemId: number;
  workRequestId: number;
  endDate: string;
  recurringScheduleType: RecurringScheduleOptions;
};
